<template>
	<div>
		<mafia0401
			v-if="is_0401"
			:items_wallet="items_wallet"

			@cancel="is_0401 = false"
			@click="onWallet"
		></mafia0401>

		<PIN
			v-if="is_pin"
			:options="pin_option"

			@cancel="is_pin = false"
			@click="onOtp"

			style="position: fixed; left: 0; top: 0; width: 100%; height: 100%; z-index: 1002"
		></PIN>

		<OTP
			v-if="is_otp"
			style="position: fixed; left: 0; top: 0; width: 100%; z-index: 101; background-color: white"

			@click="postDrop"
			@cancel="is_otp = false"
		></OTP>

	</div>
</template>

<script>
import mafia0401 from '@/view/Drops_old/mafia040-1'
import PIN from "@/view/Auth/mafia007";
import OTP from "@/view/Auth/mafia111-6";
export default {
	name: 'mafia040'
	, props: ['user']
	, components: {PIN, mafia0401, OTP}
	, data: function(){
		return {
			program: {
				name: 'NFT 카드 구매'
				, title: 'UNION CARD'
				, type: 'popup'
				, not_footer: true
				, from: 'drops'
			}
			, card_type: this.$route.params.card_type

			, item_wallet: {

			}
			, item_currency: {
				label: ''
			}
			, wallet: []
			, is_pin: false
			, pin_option: {
				pin_type: 'check'
			}
			, is_otp: false
			, pin_number: ''
			, is_0401: false
			, items_currency: []
			, fee_currency: {
				label: 'klay'
			}
			, drops_config:[
				{ transaction_coin_quantity: 0, transaction_commis_coin_quantity: 0}
				, { transaction_coin_quantity: 0, transaction_commis_coin_quantity: 0}
			]
		}
	}
	, computed: {
		price: function(){
			let price = 0

			console.log('this.item_wallet', this.item_wallet)
			if(this.item_wallet.coin_token_code == 'BC00200002'){
				price = this.drops_config[0].transaction_coin_quantity
			}else{
				price = this.drops_config[1].transaction_coin_quantity
			}

			return price
		}
		,fee: function(){
			let fee = 0

			if(this.item_wallet.coin_token_code == 'BC00200002'){
				fee = this.drops_config[0].transaction_commis_coin_quantity
			}else{
				fee = this.drops_config[1].transaction_commis_coin_quantity
			}

			return fee
		}
		,total: function(){
			let total = 0
			if(this.item_wallet.coin_token_code == 'BC00200002'){
				total = Number(this.price) + Number(this.fee)
			}else{
				total = Number(this.price)
			}

			return total
		}
		, items_wallet: function(){
			return this.wallet.filter(function(item){
				if(item.coin_token_code == 'BC00200002'){
					item.label = 'klay'
				}else if(item.coin_token_code == 'BC00200003'){
					item.label = 'mafi'
				}

				return item
			})
		}
		, is_payment: function(){
			let t = false
			if(this.item_wallet.holding_coin_quantity >= this.total){
				t = true
			}
			return t
		}
	}
	, methods: {
		getNftConfig: async function(){

			try {
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.get_drops_config
					, data: {
						member_number: this.user.member_number
					}
					, type: true
				})

				if(result.success){
					this.drops_config = result.data.transaction_info_list
					console.log('this.drops_config', this.drops_config)
				}else{
					throw result.message
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, getNftUtilityConfig: async function(){

			try {
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.get_drops_config_utility
					, data: {
						member_number: this.user.member_number
					}
					, type: true
				})

				if(result.success){
					this.drops_config = result.data.transaction_info_list
					console.log('this.drops_config', this.drops_config)
				}else{
					throw result.message
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,getWallets: async function(){
			try {
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.get_wallet_list
					, data: {
						member_number: this.user.member_number
					}
					, type: true
				})

				if(result.success){
					this.wallet = result.data.wallet_list
					console.log('this.items_wallet', this.items_wallet)
					if(this.wallet.length > 0){
						this.item_wallet = this.wallet[0]
					}else{
						this.$bus.$emit('notify', { type: 'error', message: '지갑 생성 후 이용가능합니다'})
					}
				}else{
					throw result.message
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onWallet: function(wallet){
			console.log('onWallet ', wallet)
			this.item_wallet = wallet
			this.is_0401 = false
		}
		, postDrop: async function(otp){
			let url = this.$api_url.api_path.post_nft_drop
			if(this.card_type == 'utility'){
				url = this.$api_url.api_path.post_utility_drop
			}
			try {
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: url
					, data: {
						member_number: this.user.member_number,
						pinnumber: this.pin_number,
						gogle_otp_certfc_number: otp,
						coin_token_code: this.item_wallet.coin_token_code,
						transaction_coin_quantity: this.price,
						transaction_commis_coin_quantity: this.fee
					}
					, type: true
				})

				if(result.success){

					if(this.card_type == 'utility') {
						this.$emit('to', { name: 'mafia043', params: { card_type: 'utility', nft_id: result.data.utilty_nft_card_number }})
					}else{
						this.$emit('to', { name: 'mafia043', params: { card_type: 'nft', nft_id: result.data.mafi_nft_card_number }})
					}

				}else{
					throw result.message
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
				this.is_pin = false
			}
		}
		, toPin: function(){
			if(this.total > this.item_wallet.amount){
				this.$bus.$emit('notify', { type: 'error', message: '보유 코인이 부족합니다. 지갑을 확인하세요', config: { bottom: '50%'}})
			}else{
				this.is_pin = true
			}
		}
		, onOtp: function(pin){
			this.is_pin = false
			this.is_otp = true
			this.pin_number = pin
		}
	}
	, async created() {
		this.$emit('onLoad', this.program)
		await this.getCurrency()
		await this.getWallets()
		console.log('this.card_type', this.card_type)
		if(this.card_type == 'nft'){
			await this.getNftConfig()
		}else{
			await this.getNftUtilityConfig()
		}
	}
}
</script>